import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './Home';
import Header from './components/Header';
import Footer from './components/Footer';
import Blog from './Blog';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import About from './components/About';
import Terms from './components/Terms';
import Contact from './components/Contact';
import User from './components/User';
import BlogDetail from './components/BlogDetail';
import ImageFetcher from './components/ImagesGet';

function App() {
  return (
    <Router>
      <Header />
      <div className="content">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/terms-and-condition" element={<Terms />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/images" element={<ImageFetcher />} />
          {/* Dynamic route for User with username parameter */}
          <Route path="/user/:username" element={<User />} />
          <Route path="/blog/:slug" element={<BlogDetail />} />

        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
