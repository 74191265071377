import React from 'react'

export default function About() {
  return (
    <>
     <div className="container blog-detail my-4">
     <div className='row'> 
     <div className="blog-content">
            <div>
            <h1 className='text-center'>About US</h1>    
            
                <p>
                Welcome to Insa Story, your go-to platform for anonymous Instagram story viewers with ease and privacy. Our tool lets users explore stories and profiles discreetly, offering a seamless experience without leaving a trace. Insa Story ensures complete anonymity and simplicity, whether you want to keep up with friends or explore new accounts. Dive into the world of Instagram stories with confidence and curiosity.
                </p>
            </div>

            <div>
            <h2 className='text-center mt-5'>Why Choose Insa Story?</h2>  <br/>  
            <h3>Privacy, User Experience & 100% Uptime</h3>
                <p>
                Insa Story is your premier choice for anonymous Instagram story viewers, offering unmatched privacy and simplicity. Our intuitive tool ensures seamless exploration without leaving a digital footprint. Insa Story guarantees a discreet, hassle-free experience for casual browsers and social media enthusiasts. Discover stories effortlessly and maintain your privacy with our reliable, user-friendly service.
                </p>
            </div>
        </div>
    </div>    
    </div>
    </>
  )
}
