import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory

export default function SearchBar() {
  const [username, setUsername] = useState('');
  const navigate = useNavigate(); // Use navigate for navigation

  const handleSubmit = (e) => {
    e.preventDefault();
    
    let extractedUsername = username;
    
    // Check if the input is a full URL and extract the username
    if (username.startsWith('https://www.instagram.com/')) {
      const urlParts = username.split('/');
      extractedUsername = urlParts[urlParts.length - 2]; // Extract username
    }
    
    // Navigate only if there's a valid username
    if (extractedUsername) {
      navigate(`/user/${extractedUsername}`); // Navigate to the user page with the username
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="input-group my-5 border rounded-pill p-1">
          <div className="input-group-prepend border-0">
            <button id="button-addon4" type="submit" className="btn btn-link text-info">
              <svg
                width="42"
                height="42"
                viewBox="0 0 42 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M37.6776 35.8225L30.0318 28.1785C32.0408 25.7547 33.25 22.645 33.25 19.25C33.25 11.5185 26.9815 5.25 19.25 5.25C11.5185 5.25 5.25 11.5185 5.25 19.25C5.25 26.9815 11.5185 33.25 19.25 33.25C22.6432 33.25 25.7547 32.0407 28.1785 30.0335L35.8224 37.6775C36.0849 37.94 36.4175 38.0625 36.75 38.0625C37.0825 38.0625 37.4151 37.94 37.6776 37.6775C38.1851 37.17 38.1851 36.33 37.6776 35.8225Z"
                  fill="white"
                  fillOpacity="0.83"
                />
              </svg>
            </button>
          </div>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Instagram username"
            aria-describedby="button-addon4"
            className="form-control bg-none border-0"
          />
        </div>
      </form>
    </div>
  );
}
