import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

const Card = ({ post }) => {
  const imageUrl = `https://insastory.com/backend/uploads/${post.image}`;
  return (
    <Link to={`/blog/${post.slug}`} className="card-link">
    <div className="card cardMainDesign">
      <img src={imageUrl} alt={post.title} width="400" height="300" className="card-img-top" />
      <div className="card-body">
        <h5 className="card-title">{post.title}</h5>
        <p className="card-text">{post.meta_description}</p>
        <a href={`/blog/${post.slug}`}>Read more</a>
        <hr />
        <div className='d-flex justify-content-start'>
          <img src={`/author.png`} alt={post.author} width="55" height="55" className="card-img-author" />
          <h5 className='p-2'>{post.author}</h5>
        </div>
        <div className='card-last d-flex justify-content-end'>
          <FontAwesomeIcon icon={faCalendarAlt} />
          <h5 className='p-2'>{post.date}</h5> {/* Adjust this as necessary */}
        </div>
      </div>
    </div>
    </Link>
  );
};

export default Card;
