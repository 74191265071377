import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [companyDropdownOpen, setCompanyDropdownOpen] = useState(false);
  const [resourcesDropdownOpen, setResourcesDropdownOpen] = useState(false);

  const companyDropdownRef = useRef(null);
  const resourcesDropdownRef = useRef(null);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const toggleCompanyDropdown = () => {
    setCompanyDropdownOpen(!companyDropdownOpen);
  };

  const toggleResourcesDropdown = () => {
    setResourcesDropdownOpen(!resourcesDropdownOpen);
  };

  const handleOutsideClick = (e) => {
    if (companyDropdownRef.current && !companyDropdownRef.current.contains(e.target)) {
      setCompanyDropdownOpen(false);
    }
    if (resourcesDropdownRef.current && !resourcesDropdownRef.current.contains(e.target)) {
      setResourcesDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (companyDropdownOpen || resourcesDropdownOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [companyDropdownOpen, resourcesDropdownOpen]);

  return (
    <header>
      <nav className="navbar sticky-top navbar-expand-lg">
        <div className="container container_nav">
          <Link className="navbar-brand" to="/">
            <img src={`/insastory.png`} alt="InsaStory" width="200" height="40" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            aria-controls="navbarSupportedContent"
            aria-expanded={isOpen ? 'true' : 'false'}
            aria-label="Toggle navigation"
            onClick={toggleNavbar}
          >
         <FontAwesomeIcon icon={faBars} />
          </button>
          <div className={`collapse navbar-collapse justify-content-end ${isOpen ? 'show' : ''}`} id="navbarSupportedContent">
            <ul className="navbar-nav nav_menu">
              <li className="nav-item dropdown" ref={companyDropdownRef}>
              <Link
  className="nav-menu-text dropdown-toggle"
  to="#"
  onClick={(e) => {
    e.preventDefault(); // Prevents the default link behavior
    toggleCompanyDropdown();
  }}
>
  Company
</Link>
                <div className={`dropdown-menu ${companyDropdownOpen ? 'show' : ''}`}>
                  <Link to="/about-us" className="dropdown-item">About Us</Link>
                  <Link to="/terms-and-condition" className="dropdown-item">Terms And Conditions</Link>
                </div>
              </li>
              <li className="nav-item dropdown" ref={resourcesDropdownRef}>
                <Link className="nav-menu-text dropdown-toggle" href="#" onClick={toggleResourcesDropdown}>
                  Resources
                </Link>
                <div className={`dropdown-menu ${resourcesDropdownOpen ? 'show' : ''}`}>
                  <Link to="/blog" className="dropdown-item">Blog</Link>
                </div>
              </li>
              <li className="nav-item">
                <a className="btn2" href="/">English</a>
              </li>
              <li className="nav-item">
                <Link className="btn1" to="/contact-us">Contact us</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
