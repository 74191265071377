import React, { useState } from 'react';
import axios from 'axios';

const InstagramDataFetcher = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        'https://api.allorigins.win/raw?url=https://www.save-free.com/process',
        new URLSearchParams({
          instagram_url: 'https://www.instagram.com/p/C5YzVSER4B8/',
          type: 'media',
          resource: 'save',
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            'Accept': 'text/html, */*; q=0.01',
            'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/128.0.0.0 Safari/537.36',
            'Referer': 'https://www.save-free.com/photo-downloader/',
            'Origin': 'https://www.save-free.com',
            'X-Requested-With': 'XMLHttpRequest',
            'Accept-Encoding': 'gzip, deflate, br, zstd',
            'Accept-Language': 'en-GB,en-US;q=0.9,en;q=0.8',
            // 'Cookie': '_ga=GA1.1.1113962401.1724936929; cf_clearance=C3T7eyvBHGWqU4gzITd.05omGGKr6PyXRMl8fW_822U-1724936930-1.2.1.1-HccGPIQEFfpB5UO1t8Rz_WarxE.0pJ1Uaw.iSUf_B6p_lw20eVqueLsxNr4bMCU5_b.gOB7oh9CflGVyf4akRSHReGrXsXeTtp2C.xGL.OhmPCDK6KQNCu3Sd6YKPwvC1LiX2jJ58iWwxfbRdBayddmh_KsPmN1LmDga_pp2iqUtl55B8RZlwyL4b5ihIwTETiyKzM2kZKZf94i4aXaJ.xSGWIUDSaG7gNQlk0ICIrQBQ7o_fuhZ3D61D06_A_G.GKfEkr87X3k6OmrK.6ImbOtrnLfA7wtM15EO8lAHJydaazcU9ZEnAGnKMBcS9NIlhsbAENrvmlqX1Hhl3PaquYpITdE6xX1vteykZBD0NQS2sfTs.Iw.Pj2PCPadhuUCpW65oNgZrQqP7N0PdfkUNg; _gcl_au=1.1.1577599000.1724936930; HstCfa4752989=1724936930122; HstCla4752989=1724936930122; HstCmu4752989=1724936930122; HstPn4752989=1; HstPt4752989=1; HstCnv4752989=1; HstCns4752989=1; c_ref_4752989=https%3A%2F%2Fwww.google.com%2F; __gads=ID=f06a725d784efe76:T=1724936930:RT=1724936930:S=ALNI_MZPiFPFf_LfccOViY8R7GHFLj6MkQ; __gpi=UID=00000eac4f7d6eb4:T=1724936930:RT=1724936930:S=ALNI_MbuI6rv75UPic2SlJc_fW9GDMbfFw; __eoi=ID=6489bdcc46f03092:T=1724936930:RT=1724936930:S=AA-Afjaj_NjsPrPQS-HJxrxYdiU3; FCNEC=%5B%5B%22AKsRol8qAQWtYMwJnwxBagSL_TrgTDdF3PqxYzx34GMjnNSkksaOIOSCBtRqiVTCeD01eK8kD9HTwOjbZUiOCtEx2kWsc_8lXZiax_K0w-H3qNVqduocER4V66Ie4hcaLfjBqbd-4nMQTjLASxLE3DGDDYB0P_UtMQ%3D%3D%22%5D%5D; _ga_9M9G1NYVWE=GS1.1.1724936929.1.0.1724936941.48.0.0; _ga_TCKL78VSRE=GS1.1.1724936929.1.0.1724936941.48.0.0'
          }
        }
      );
      setData(response.data);
    } catch (err) {
      setError('An error occurred while fetching data');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1>Instagram Data Fetcher</h1>
      <button onClick={fetchData} disabled={loading}>
        {loading ? 'Loading...' : 'Fetch Data'}
      </button>

      {error && <p style={{ color: 'red' }}>{error}</p>}

      {data && (
        <div>
          <h2>Response Data:</h2>
          <pre>{JSON.stringify(data, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default InstagramDataFetcher;
