import React from 'react'

export default function Contact() {
  return (
    <>
     <div className="container blog-detail my-4">
     <div className='row'> 
     <div className="blog-content">
            <div className='my-4'>
            <h1 className='text-center'>Contact US </h1>    
            </div>
            <h2>Get in Touch with Us</h2>
            <p>Welcome to Insa Story, your premier destination for an immersive Instagram viewing experience. Discover a world where every Instagram story becomes a captivating visual journey. Our platform offers a viewing experience beyond the ordinary, giving you a front-row seat to the vibrant tapestry of fascinating stories. Explore and engage with content like never before</p>
            <h2 className='mt-4'>Send Us a Message</h2>
            <p>We’d love to hear from you! Feel free to reach out if you have questions, or feedback or need assistance. Our team is ready to help and eager to provide your support. Send us a message, and we’ll reply as soon as possible.</p>
            <p className='text-center'><a className='btn-inst' href="mailto:insastory06@gmail.com">Send email</a></p>
        </div>
    </div>    
    </div>
    </>
  )
}
