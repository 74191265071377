import React from 'react'

export default function Terms() {
  return (
    <>
     <div className="container blog-detail my-4">
     <div className='row'> 
     <div className="blog-content">
            <div>
            <h1 className='text-center'>Terms And Condition</h1>    
            </div>

            <div>
            <h2 className='mt-5'>Agreement to Terms</h2> 
                <p>
                Welcome to Insa Story You agree to comply with these Terms and Conditions by accessing or using our service. If you disagree with these terms, please discontinue using our website.
                </p>
                <h2 className='mt-5'>Service Overview                </h2> 
                <p>
                Insa Story offers a unique tool for anonymous Instagram story viewers. By using our platform, you agree to:
                </p>
                <ul>
                  <li>Use the service solely for personal, lawful purposes.</li>
                  <li>Refrain from engaging in any activity that may harm or infringe upon the rights of others.</li>
                  <li>Acknowledge that you are responsible for your actions and interactions on our platform.</li>
                </ul>
                <h2 className='mt-5'>Privacy Assurance  </h2> 
                <p>
                We prioritize your privacy and ensure that:
                </p>
                <ul>
                  <li>Insa Story does not collect, store, or share any personal data from users.</li>
                  <li>Our platform operates with complete anonymity, ensuring your browsing activities remain private.</li>
                </ul>
                <h2 className='mt-5'>Intellectual Property Rights </h2> 
                <p>
                All content on Insa Story, including trademarks, graphics, and text, is protected by intellectual property laws. You may not reproduce, distribute, or create derivative works from our content without our permission.
                </p>
                <h2 className='mt-5'>Disclaimer of Warranties</h2> 
                <p>
                Insa Story provides the service on an &QUOT;as is&QUOT; basis without any warranties. We do not guarantee that the service will be uninterrupted or error-free. Your use of the service is at your own risk.
                </p>
                <h2 className='mt-5'>Limitation of Liability</h2> 
                <p>
                Insa Story is not liable for any direct, indirect, incidental, or consequential damages resulting from the use or inability to use our service. This includes, but is not limited to, any data loss or profits.
                </p>
                <h2 className='mt-5'>Modifications to Terms</h2> 
                <p>
                We may update these Terms and Conditions from time to time. Any changes will be posted on this page, and your continued use of Insa Story signifies your acceptance of the new terms.
                </p>
                <h2 className='mt-5'>Termination of Access</h2> 
                <p>
                Insa Story reserves the right to terminate or suspend access to our service without notice if you violate these Terms and Conditions.
                </p>
                <h2 className='mt-5'>Governing Law</h2> 
                <p>
                These Terms and Conditions are governed by the laws of the jurisdiction in which Insa Story operates. Any disputes arising from these terms shall be resolved in the appropriate legal venues.
                </p>
                <h2 className='mt-5'>User Conduct</h2> 
                <p>
                We expect our users to interact with Insa Story respectfully and ethically. By using our service, you agree not to:
                </p>
                <ul>
                  <li>Misuse the platform for any illegal or unauthorized purposes.</li>
                  <li>Attempt to gain unauthorized access to our systems or engage in any activity that disrupts the functionality of our service.
                  </li>
                  <li>Use automated systems or software to extract data from our site without permission.
                  </li>
                </ul>
                <h2 className='mt-5'>Entire Agreement                </h2> 
                <p>
                These Terms and Conditions constitute the entire agreement between you and Insa Story regarding the use of our service. They supersede any prior agreements or understandings, whether written or oral.
                </p>
                <h2 className='mt-5'>Waiver   </h2> 
                <p>
                The failure of Insa Story to enforce any right or provision of these Terms and Conditions shall not constitute a waiver of such right or provision. Any waiver of any provision of these terms will be effective only if in writing and signed by an authorized representative of Insa Story.
                </p>
                <h2 className='mt-5'>Age Requirement   </h2> 
                <p>
                You must be at least 13 years old to use Insa Story. Using our service, you represent and warrant meeting this age requirement.
                </p>
                <h2 className='mt-5'>Updates and Changes   </h2> 
                <p>
                Insa Story reserves the right to modify or discontinue any aspect of the service at any time, with or without notice. We encourage you to review these Terms and Conditions regularly to stay informed about any changes.
                </p>
                
            </div>
        </div>
    </div>    
    </div>
    </>
  )
}
