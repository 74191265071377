import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer_container text-white pt-4 pb-4">
      <div className="container ">
        <div className="row">
          <div className="col-md-3">
          <Link className="navbar-brand" to="/">
            <img src={`/insastory.png`} alt="InsaStory" width="200" height="40" />
          </Link>
            <p className='mt-5'>
            InsaStory is not connected with Instagram. We do not host any of the Instagram content on our servers. All content belongs to the content authors. Download is available for informational purposes only.
            </p>
          </div>
          <div className="col-md-5 d-flex justify-content-between">
            <h5><a href='/'><u>Follow Us</u></a></h5>
            <h5><a href='/'><u>Resources</u></a></h5>
            <h5><a href='/'><u>Company</u></a></h5>
            <h5><a href='/'><u>Support</u></a></h5>
          </div>
          <div className="col-md-4 px-5">
            <h5>Instagram Highlights Viewers</h5>
            <h5>Instagram Story Download</h5>
            <h5>Instagram Reel Video Downloader</h5>
            <h5>Policies</h5>
            <h5>Terms of Service</h5>
           
          </div>
        </div>
        <div className="text-center mt-3">
          <p className='fs-5'>&copy; {new Date().getFullYear()} InstaStory. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
