import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // For accessing URL parameters
import axios from 'axios';
import { HashLoader } from 'react-spinners'; // Import the spinner component
import './InstagramProfile.css';

export default function User() {
  const { username } = useParams(); // Get the username from the route parameters
  const [userData, setUserData] = useState(null); // State to store user data
  const [error, setError] = useState(null); // State to store error messages
  const [loading, setLoading] = useState(true);
  const [highlights, setHighlights] = useState([]); // State to store highlights data
  const [highlightsLoading, setHighlightsLoading] = useState(false);



  useEffect(() => {
    if (username) {
      setLoading(true);
      axios
        .get(`https://api.allorigins.win/raw?url=https://api-ig.storiesig.info/api/userInfoByUsername/${username}`)
        .then(response => {
          setUserData(response.data.result.user); // Set user data
        })
        .catch(error => {
          console.error('Error fetching user data:', error); // Log error
          setError('Error fetching user data'); // Set error message
        })
        .finally(() => {
          setLoading(false); // Set loading to false after fetching is complete
        });
    }
  }, [username]);

  const fetchHighlights = () => {
    if (userData && userData.pk) {
      setHighlightsLoading(true);
      const url = `https://api.allorigins.win/raw?url=https://api-ig.storiesig.info/api/highlights/${userData.pk}`;
      axios.get(url)
        .then(response => {
          // console.log('Highlights Data:', response.data.result);
          const dataH = response.data.result;
          setHighlights(dataH || []); // Set highlights data directly
        })
        .catch(error => {
          console.error('Error fetching highlights:', error);
          setError('Error fetching highlights');
        })
        .finally(() => {
          setHighlightsLoading(false);
        });
    }
  };
  

  

  return (
    <div className="user-info">
      {loading ? (
        <div className="spinner-container">
          <HashLoader
            color="#db2b80"
            loading={loading}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : error ? (
        <p className="error-message">{error}</p>
      ) : userData ? (
        <>
          <div className="avatar user-info__avatar">
            <div className="avatar__wrapper">
              {userData.profile_pic_url && (
                <img
                  className="avatar__image"
                  src={`https://api.allorigins.win/raw?url=${encodeURIComponent(userData.profile_pic_url)}`}
                  alt="avatar"
                />
              )}
              <a
                className="avatar__button"
                href={userData.hd_profile_pic_url_info?.url}
                download
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="none"
                  className="avatar__icon"
                >
                  <rect width="40" height="40" x="5" y="5" rx="20"></rect>
                  <path
                    fill="#1B4631"
                    fillRule="evenodd"
                    d="M34.52 33.75c0 .46-.388.833-.866.833H16.346c-.478 0-.865-.373-.865-.833v-3.333H13.75v3.333c0 1.38 1.162 2.5 2.596 2.5h17.308c1.434 0 2.596-1.12 2.596-2.5v-3.333h-1.73v3.333Zm-8.943-2.164 7.255-6.253c.592-.51.217-1.455-.577-1.455H28.46V13.75H21.54v10.128h-3.794c-.794 0-1.169.944-.577 1.454l7.255 6.254a.89.89 0 0 0 1.154 0Z"
                    clipRule="evenodd"
                  ></path>
                  <rect
                    width="47.414"
                    height="47.414"
                    x="1.293"
                    y="1.293"
                    strokeWidth="2.586"
                    rx="23.707"
                  ></rect>
                </svg>
              </a>
            </div>
          </div>
          <p className="user-info__username">
            <a
              className="user-info__link"
              href={`https://www.instagram.com/${userData.username}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="user-info__username-text">@{userData.username}</span>
            </a>
          </p>
          <ul className="stats user-info__stats">
            <li className="stats__item">
              <span className="stats__value">{userData.media_count}</span>
              <span className="stats__name">posts</span>
            </li>
            <li className="stats__item">
              <span className="stats__value">{userData.follower_count}</span>
              <span className="stats__name">followers</span>
            </li>
            <li className="stats__item">
              <span className="stats__value">{userData.following_count}</span>
              <span className="stats__name">following</span>
            </li>
          </ul>
          <p className="user-info__full-name">{userData.full_name}</p>
          <pre className="user-info__biography">
            {userData.biography}
          </pre>
         {/* User stats and other info */}
         <button onClick={fetchHighlights} className="btn-inst active my-4">Highlights</button>
          {highlightsLoading ? (
             <HashLoader
             color="#db2b80"
             loading={loading}
             size={150}
             aria-label="Loading Spinner"
             data-testid="loader"
           />
          ) : (
            <ul className="highlights-component">
             {highlights && highlights.length > 0 ? (
  highlights.map((highlight, index) => (
    <div className='highlight-box' 
                key={index}
    >
      <img 
      // src={highlight.cover_media.cropped_image_version.url} alt={highlight.title} 
      src={`https://api.allorigins.win/raw?url=${encodeURIComponent(highlight.cover_media.cropped_image_version.url)}`}
      alt={highlight.title} 
      />
      <h6>{highlight.title}</h6>
    
    </div>
  ))
) : (
  <p>Click Highlights Button to get Highlights</p>
)}
            </ul>
          )}
        </>
      ) : (
        <p>No data available</p>
      )}
     
    </div>
  );
}
