import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HashLoader } from 'react-spinners'; // Import the spinner component
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const BlogDetail = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [toc, setToc] = useState([]); // For the table of contents
  const [isTocVisible, setIsTocVisible] = useState(false); // Toggle visibility

  useEffect(() => {
    axios.get(`https://insastory.com/backend/api/blog.php?slug=${slug}`)
      .then(response => {
        const postData = response.data.data;
        setPost(postData);
        setLoading(false);
        generateTOC(postData.description); // Generate TOC based on post content
      })
      .catch(error => {
        setError('Error fetching blog post');
        setLoading(false);
      });
  }, [slug]);

  // Function to generate the table of contents
  const generateTOC = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const headings = doc.querySelectorAll('h2, h3'); // Adjust this based on which headings you want in TOC
    const tocItems = [];

    headings.forEach((heading, index) => {
      const id = `heading-${index}`;
      heading.id = id;
      tocItems.push({
        id,
        text: heading.textContent,
        level: heading.tagName.toLowerCase() // to handle h2, h3, etc.
      });
    });

    setToc(tocItems);
  };

  // Toggle the visibility of TOC
  const handleTocToggle = () => {
    setIsTocVisible(prevState => !prevState);
  };

  if (loading) return <div className="spinner-container">
  <HashLoader
     color="#db2b80"
    loading={loading}
    size={150}
    aria-label="Loading Spinner"
    data-testid="loader" // Customize size if needed
  />
</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="container blog-detail">
      <div className="row">
        <div className='col-md-9'>
          <h1 className="pt-4 px-4">{post.title}</h1>
        </div>
        <div className="col-md-3">
          <div className="author">
            <img
              className="author__avatar"
              src={`/author.png`}
              alt={`${post.author} profile`}
            />
            <div className="author__details">
              <div>written by <br/><a href="/" rel="author">{post.author}</a></div>
              <time title={post.date}>{post.date}</time>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <img
            src={`https://insastory.com/backend/uploads/${post.image}`}
            alt={post.title}
            width="800"
            height="600"
            className="img_banner img-fluid"
            priority
          />
          <div className="toc">
            <h4 onClick={handleTocToggle} className="toc-header">
            <FontAwesomeIcon icon={isTocVisible ? faChevronUp : faChevronDown} />
            {isTocVisible ? ' Hide Table of Contents' : ' Table of Contents'}
            </h4>
            {isTocVisible && (
              <ul className="toc-list">
                {toc.map(item => (
                  <li key={item.id} className={`toc-item ${item.level}`}>
                    <a href={`#${item.id}`}>{item.text}</a>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="blog-content">
            <div dangerouslySetInnerHTML={{ __html: post.description }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
